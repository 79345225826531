<i18n lang="json" locale="fr-FR">
{
  "copyright": "© copyright {year} Crédit Agricole"
}
</i18n>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';

import type { NavigationItem } from '~/types/graphql';

withDefaults(
  defineProps<{
    items?: NavigationItem[];
  }>(),
  {
    items: () => [],
  },
);

const { t } = useI18n();

const year = new Date().getFullYear();
</script>

<template>
  <div class="layout-footer-legals">
    <NavigationList
      v-if="items.length > 0"
      list-css-class="layout-footer-legals__list"
      item-css-class="layout-footer-legals__item"
      :items="items"
    />

    <div class="layout-footer-legals__copyright">{{ t('copyright', { year }) }}</div>
  </div>
</template>

<style lang="scss">
@use '@onliz/design-system-scss' as ods;

.layout-footer-legals {
  $layout-footer-legals--border: 2px solid var(--scheme--border);

  display: flex;
  gap: var(--spacing--s);
  align-items: center;
  flex-direction: column;
  font-size: var(--extra-small--font-size);

  @media screen and (min-width: ods.$breakpoint-s) {
    flex-direction: row;
  }

  &__list {
    display: inline-flex;
    gap: var(--spacing--s);
    text-align: center;
  }

  &__item {
    padding: 0 var(--spacing--s) 0 0;
    border-right: $layout-footer-legals--border;

    &:last-child {
      @media screen and (max-width: ods.$breakpoint-s - 1) {
        border-right: 0;
        padding-right: 0;
      }
    }
  }
}
</style>
