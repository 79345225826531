<script setup lang="ts">
import { OdsClickableElement } from '@onliz/design-system-vue';
import type { Component } from 'vue';

import RefurbishedIcon from '~/assets/icons/custom/refurbished.svg?component';
import type { NavigationItem } from '~/types/graphql';

const props = withDefaults(
  defineProps<{
    items: NavigationItem[];
    iconCssClass?: string;
    itemCssClass?: string;
    labelCssClass?: string;
    linkCssClass?: string;
    listCssClass?: string;
  }>(),
  {
    iconCssClass: 'navigation-list__icon',
    itemCssClass: 'navigation-list__item',
    labelCssClass: 'navigation-list__label',
    linkCssClass: 'navigation-list__link',
    listCssClass: 'navigation-list',
  },
);

const NavigationlistItemIconMap: Record<string, Component> = {
  refurbished: RefurbishedIcon as Component,
};

const itemsMeta = computed<{ iconComponent: Component | undefined }[]>(() => {
  return props.items.map(({ icon }) => {
    const iconComponent = (icon && NavigationlistItemIconMap[icon]) || undefined;

    return { iconComponent: iconComponent };
  });
});
</script>

<template>
  <ul :class="listCssClass">
    <li v-for="(item, i) in items" :key="item.id" :class="itemCssClass">
      <OdsClickableElement :class="linkCssClass" :to="item.path ?? undefined">
        <Component :is="itemsMeta[i].iconComponent" v-if="itemsMeta[i].iconComponent" :class="iconCssClass" />

        <span :class="labelCssClass">{{ item.title }}</span>
      </OdsClickableElement>
    </li>
  </ul>
</template>

<style lang="scss">
.navigation-list {
  &__link {
    &:hover {
      text-decoration: underline;
    }

    &.router-link-active {
      font-weight: bold;
    }
  }

  &__icon {
    width: 1.1em;
    margin: 0 var(--spacing--xs) 0 0;
  }
}
</style>
