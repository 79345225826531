export enum CmsHeaderNavigationSlug {
  main = 'header-main',
  aside = 'header-aside',
}

export enum CmsFooterNavigationSlug {
  about = 'footer-about',
  customers = 'footer-customer',
  legals = 'footer-legals',
}
