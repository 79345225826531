import { useI18n } from 'vue-i18n';

export const useUseOrganizationJsonLd = () => {
  const {
    public: { siteUrl },
  } = useRuntimeConfig();
  const { t } = useI18n({ useScope: 'global' });

  useJsonld('organization', {
    /* eslint-disable @typescript-eslint/naming-convention*/
    '@context': 'https://schema.org',
    '@type': 'Organization',
    /* eslint-enable @typescript-eslint/naming-convention*/
    url: siteUrl,
    name: t('global.brandName'),
    description: t('global.description'),
    sameAs: [
      t('global.networks.instagram.url'),
      t('global.networks.facebook.url'),
      t('global.networks.linkedIn.url'),
      t('global.networks.x.url'),
      t('global.networks.youtube.url'),
    ],
    contactPoint: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      '@type': 'ContactPoint',
      contactType: t('global.support.name'),
      email: t('global.support.customerEmail'),
      telephone: t('global.support.phone.international'),
    },
  });
};
