<script lang="ts" setup>
import type { HtmlTagName } from '@onliz/design-system-vue';
import { useResizeObserver } from '@vueuse/core';
import type { Component } from 'vue';

import useLayoutStickiness from '~/composables/layout/useLayoutStickiness';

withDefaults(
  defineProps<{
    tag?: HtmlTagName | Component;
  }>(),
  {
    tag: 'div',
  },
);

const model = defineModel<boolean>();
const rootElementRef = ref<HTMLElement>();
const barElementRef = ref<HTMLElement>();
const { isSticky, isExpanded, isTransitioned } = useLayoutStickiness(rootElementRef);

useResizeObserver(rootElementRef, (entries) => {
  const entry = entries[0];
  const { height } = entry.contentRect;

  if (rootElementRef.value) {
    rootElementRef.value.style.height = `${height}px`;
  }
});

onMounted(() => {
  watch(isSticky, (value) => {
    model.value = value;
  });
});
</script>

<template>
  <component
    :is="tag"
    ref="rootElementRef"
    class="layout-sticky-bar"
    :data-sticky="isSticky"
    :class="{
      'layout-sticky-bar--sticky': isSticky,
      'layout-sticky-bar--expanded': isExpanded,
      'layout-sticky-bar--transitioned': isTransitioned,
    }"
  >
    <div ref="barElementRef" class="layout-sticky-bar__bar">
      <slot></slot>
    </div>
  </component>
</template>

<style lang="scss">
@use '@onliz/design-system-scss' as ods;

:root {
  --layout-sticky-bar--offset: 80px;
}

.layout-sticky-bar {
  $block-selector: &;

  --layout-sticky-bar--transition-duration: var(--default--transition-duration);

  transition:
    background-color var(--layout-sticky-bar--transition-duration),
    color var(--layout-sticky-bar--transition-duration);
  z-index: 1;

  &__bar {
    #{$block-selector}--sticky & {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      transform: translateY(-100%);
    }

    #{$block-selector}--expanded & {
      transform: translateY(0);
      box-shadow: var(--shadow--m);
    }

    #{$block-selector}--transitioned & {
      transition:
        transform 600ms ods.$ease-out-cubic,
        box-shadow 600ms ods.$ease-out-cubic;
    }
  }
}
</style>
