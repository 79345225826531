import {
  type MaybeComputedElementRef,
  type MaybeElement,
  useEventListener,
  useIntersectionObserver,
} from '@vueuse/core';
import { type MaybeRefOrGetter, onMounted, ref } from 'vue';

export default function useLayoutStickiness(
  rootElementRef: MaybeComputedElementRef | MaybeRefOrGetter<MaybeElement[]> | MaybeComputedElementRef[],
) {
  const isSticky = ref(false);
  const isExpanded = ref(false);
  const isTransitioned = ref(false);

  let isElementVisible = false;
  let lastWindowScrollY = 0;

  onMounted(() => {
    useIntersectionObserver(rootElementRef, ([{ isIntersecting }]: IntersectionObserverEntry[]) => {
      isElementVisible = isIntersecting;
    });

    useEventListener(window, 'scroll', () => {
      const isScrollingUp = window.scrollY - lastWindowScrollY < 0;
      const shouldBecomeSticky = !isElementVisible;
      const shouldBeExpanded = shouldBecomeSticky && isScrollingUp;

      if (isSticky.value !== shouldBecomeSticky) {
        isSticky.value = shouldBecomeSticky;

        if (!shouldBeExpanded) {
          isTransitioned.value = false;
        }
      }

      if (isExpanded.value !== shouldBeExpanded) {
        isExpanded.value = shouldBeExpanded;

        if (shouldBeExpanded) {
          isTransitioned.value = true;
        }
      }

      lastWindowScrollY = window.scrollY;
    });
  });

  return {
    isSticky,
    isExpanded,
    isTransitioned,
  };
}
