import { removeQueryParameterFromUrl } from '~/utils/UrlUtils';

// eslint-disable-next-line unicorn/prevent-abbreviations
export default function useRelCanonical(value?: MaybeRefOrGetter<string>) {
  const {
    public: { siteUrl },
  } = useRuntimeConfig();

  const route = useRoute();

  const metaValue = computed(() => {
    const url = value === undefined ? `${siteUrl}${route.fullPath}` : toValue(value);

    return removeQueryParameterFromUrl(
      url,
      'srsltid', // See https://dev.azure.com/LOCAM-FINTECH/FINTECH%20-%20Marketplace/_workitems/edit/31914
      siteUrl,
    );
  });

  useHead({
    link: [
      {
        key: 'canonical',
        rel: 'canonical',
        href: metaValue,
      },
    ],
  });
}
