<i18n lang="json" locale="fr-FR">
{
  "title": "Suivez toute l’actualité Onliz sur les réseaux sociaux",
  "linkTitle": "Suivez-nous sur {name}",
  "networks": {
    "facebook": {
      "name": "@:{'global.networks.facebook.name'}",
      "url": "@:{'global.networks.facebook.url'}"
    },
    "instagram": {
      "name": "@:{'global.networks.instagram.name'}",
      "url": "@:{'global.networks.instagram.url'}"
    },
    "x": {
      "name": "@:{'global.networks.x.name'}",
      "url": "@:{'global.networks.x.url'}"
    },
    "youtube": {
      "name": "@:{'global.networks.youtube.name'}",
      "url": "@:{'global.networks.youtube.url'}"
    },
    "linkedIn": {
      "name": "@:{'global.networks.linkedIn.name'}",
      "url": "@:{'global.networks.linkedIn.url'}"
    }
  }
}
</i18n>

<script setup lang="ts">
import { OdsClickableElement } from '@onliz/design-system-vue';
import { useI18n } from 'vue-i18n';

import FacebookIcon from '~/assets/icons/social/facebook.svg?component';
import InstagramIcon from '~/assets/icons/social/instagram.svg?component';
import LinkedInIcon from '~/assets/icons/social/linkedin.svg?component';
import XIcon from '~/assets/icons/social/x.svg?component';
import YoutubeIcon from '~/assets/icons/social/youtube.svg?component';

defineProps<{
  showTitle?: boolean;
}>();

const { t } = useI18n();

const FooterSocialItems: {
  icon: Component;
  label: string;
  slug: string;
  title: string;
  url: string;
}[] = [
  {
    icon: FacebookIcon as Component,
    label: t('networks.facebook.name'),
    slug: 'facebook',
    title: t('linkTitle', { name: t('networks.facebook.name') }),
    url: t('networks.facebook.url'),
  },
  {
    icon: XIcon as Component,
    label: t('networks.x.name'),
    slug: 'instagram',
    title: t('linkTitle', { name: t('networks.x.name') }),
    url: t('networks.x.url'),
  },
  {
    icon: YoutubeIcon as Component,
    label: t('networks.youtube.name'),
    slug: 'youtube',
    title: t('linkTitle', { name: t('networks.youtube.name') }),
    url: t('networks.youtube.url'),
  },
  {
    icon: InstagramIcon as Component,
    label: t('networks.instagram.name'),
    slug: 'instagram',
    title: t('linkTitle', { name: t('networks.instagram.name') }),
    url: t('networks.instagram.url'),
  },
  {
    icon: LinkedInIcon as Component,
    label: t('networks.linkedIn.name'),
    slug: 'instagram',
    title: t('linkTitle', { name: t('networks.linkedIn.name') }),
    url: t('networks.linkedIn.url'),
  },
];
</script>

<template>
  <div class="layout-footer-social">
    <p v-if="showTitle" class="layout-footer-social__title">{{ t('title') }}</p>

    <ul class="layout-footer-social__list">
      <li v-for="item in FooterSocialItems" :key="`footer-social-${item.slug}`" class="layout-footer-social__item">
        <OdsClickableElement class="layout-footer-social__link" :to="item.url" target="_blank" :title="item.title">
          <Component :is="item.icon" class="layout-footer-social__icon" />
        </OdsClickableElement>
      </li>
    </ul>
  </div>
</template>

<style lang="scss">
@use '@onliz/design-system-scss' as ods;

.layout-footer-social {
  display: inline-flex;
  gap: var(--spacing--xs);
  align-items: center;
  flex-direction: column;

  @media screen and (min-width: ods.$breakpoint-s) {
    flex-direction: row;
    gap: var(--spacing--s);
  }

  &__title {
    font-size: var(--extra-small--font-size);
    margin: 0;
    display: none;

    @media screen and (min-width: ods.$breakpoint-l) {
      display: block;
    }
  }

  &__list {
    display: flex;
    gap: var(--spacing--m);
  }

  &__icon {
    width: 1em;
    fill: currentcolor;
  }

  &__link {
    color: var(--scheme--color-emphasis);

    &:hover {
      color: var(--scheme--color);
    }
  }
}
</style>
